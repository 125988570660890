import {ICommonData} from "../../types/common";
import {FORM_ERRORS, FORM_REGEX} from "../constants/GrievanceForm";
import {currentPageURL, getCountryFromLocale, pageToRolloutChannelMapping} from "../constants/PagesConstants";
import {GRIEVANCE_FORM_TYPES} from "../enums/enums";
import {COUNTRY} from "../constants/LocaleAndCountryConstants";

export let buttonClickType = ""

export const setButtonClickType = (type: string) => {
  buttonClickType = type
}

export const validateForm = (data, error, formData) => {
    let hasError = error || false;
    if (data.type == GRIEVANCE_FORM_TYPES.FILE) {
        if (
            data.required &&
            (data.value == undefined || data.value.length === 0)
        ) {
            data.error = FORM_ERRORS.REQUIRED_ERROR_MESSAGE;
            hasError = true;
        } else {
            data.error = "";
        }
        formData.push({
            question: data.label,
            answer: data.value,
            attachments: data.attachments,
            type: data.type,
            required: data.required
        });
    } else if (data.type == GRIEVANCE_FORM_TYPES.FORM) {
        data.attributes.forEach((el) => {
            [hasError, formData] = validateForm(el, hasError, formData) || [
                hasError,
                formData,
            ];
        });

        if (data.children) {
            const [childHasError, childData] = validateForm(data.children, hasError, formData)
            hasError = hasError || childHasError;
            formData = childData
        }

    } else if (data.type == GRIEVANCE_FORM_TYPES.RADIO) {
        if (data.required && data.selected == undefined) {
            data.error = FORM_ERRORS.REQUIRED_ERROR_MESSAGE;
            hasError = true;
        } else {
            data.error = "";
        }
        formData.push({question: data.label, answer: data.value, type: data.type, required: data.required});
        if (data.selected != undefined && data.options[data.selected].children) {
            [hasError, formData] = validateForm(
                data.options[data.selected].children,
                hasError,
                formData
            ) || [hasError, formData];
        }
    } else {
        if (data.required && (data.value == undefined || data.value == "")) {
            data.error = FORM_ERRORS.REQUIRED_ERROR_MESSAGE;
            hasError = true;
        } else {
            if (
                data.type == GRIEVANCE_FORM_TYPES.EMAIL &&
                !FORM_REGEX.EMAIL.test(data.value)
            ) {
                hasError = true;
                data.error = FORM_ERRORS.EMAIL_ERROR_MESSAGE;
            } else if (
                data.type == GRIEVANCE_FORM_TYPES.MOBILE &&
                !FORM_REGEX.MOBILE.test(data.value)
            ) {
                hasError = true;
                data.error = FORM_ERRORS.MOBILE_ERROR_MESSAGE;
            } else if (
                data.type == GRIEVANCE_FORM_TYPES.URL &&
                !FORM_REGEX.URL.test(data.value)
            ) {
                hasError = true;
                data.error = FORM_ERRORS.URL_ERROR_MESSAGE;
            } else if (
                data.type == GRIEVANCE_FORM_TYPES.GOV_EMAIL &&
                !isGovEmail(data.value)
            ) {
                hasError = true;
                data.error = FORM_ERRORS.EMAIL_ERROR_MESSAGE;
            } else {
                data.error = "";
            }
        }
        if (data.type !== GRIEVANCE_FORM_TYPES.PLAINTEXT) {
            let value = (data.type === GRIEVANCE_FORM_TYPES.DATE || data.type === GRIEVANCE_FORM_TYPES.DATETIME) ? new Date(data.value).toString() : data.value
            formData.push({question: data.label, answer: value, type: data.type, required: data.required});
        }
    }
    return [hasError, formData];
};

const isGovEmail = (email: string) => {
    if (FORM_REGEX.EMAIL.test(email) && (email.includes('nic.in') || email.includes('gov.in'))) {
        return true
    }
    return false
}

export const getFileNameFromUrl = (file: string) => {
    let fileNameArray = file.replace(/^.*[\\\/]/, "").split('_')
    return fileNameArray[fileNameArray.length - 1];
};

export const showHowToInstallVideo = (commonData: ICommonData, locale: string, utm?: string | string[] | undefined) => {
    if (
        !pageToRolloutChannelMapping.PAID_APP.includes(currentPageURL) &&
        !pageToRolloutChannelMapping.AFFILIATE_APP.includes(currentPageURL) &&
        !pageToRolloutChannelMapping.ORGANIC_APP.includes(currentPageURL) &&
        //TODO
        (commonData?.howToInstallVideo || commonData?.howToInstall?.howToInstallVideo)
    ) {
        return true;
    }
    return false;
}

export const multipleClassName = (...classNames: string[]): string => {
  return classNames.join(" ")
}

export const getLogoUrl = (locale?: string) => {
  if (getCountryFromLocale(locale) === COUNTRY.BRAZIL) {
      return "https://d3g4wmezrjkwkg.cloudfront.net/website/images/logoAsset_br.webp"
  } else {
      return "https://d3g4wmezrjkwkg.cloudfront.net/website/images/logo-gold.png"
  }
}

export const getDynamicString = (field: string, stringKey: string) => {
    return stringKey.replace("%s", field)
}